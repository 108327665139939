<template>
  <div class="about">
    <section class="hero is-small is-primary">
      <div class="hero-body">
        <p class="title">
          Client Configuration
        </p>
      </div>
    </section>
    <br>
    <div class="columns">
      <div class="column">
        <o-field label="">
          <o-button variant="success" @click="create()">Create Client</o-button>
        </o-field>
        <o-field label="">
          <o-checkbox variant="success" v-model="$store.state.showInactiveClients">Show inactive clients</o-checkbox>
        </o-field>
         <div v-if="$store.state.license.availableLicenses === -1">Total Available Licenses: unlimited</div>
         <div v-else>Total Available Licenses: {{ $store.state.license.availableLicenses }}</div>
         <div >used Licenses: {{ $store.state.license.usedLicenses }}</div>
      </div>
      <div class="column chart-container">
          <bar-chart :chartData="$store.getters.showUsedLicenseChartData"/>
      </div>
      <div class="column">
        <div class="chart-container">
          <bar-chart :chartData="$store.getters.showAvailableLicensesChartData"/>
        </div>
      </div>
    </div>

    <client-table></client-table>
  </div>
</template>

<script lang="js">
import ClientTable from '@/components/ClientTable.vue'
import CreateClientModal from '@/components/CreateClientModal.vue'
import BarChart from '@/components/BarChart.vue'

import { get } from '@/plugins/apiCall'

export default {
  components: {
    ClientTable,
    BarChart
  },
  mounted () {
    this.$store.dispatch('getAllClients')
    this.$store.dispatch('getLicenses')
  },
  methods: {
    create () {
      this.$oruga.modal.open({
        component: CreateClientModal,
        trapFocus: true
      })
    }
  }
}
</script>

<style scoped>
</style>
