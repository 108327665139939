<template>
   <div class="card">
    <div class="card-content">
      <div class="content">
        <table v-if="data">
          <tr>
            <td>
              company
            </td>
            <td>
              <o-input placeholder="Aveniq" v-model="data.company"> </o-input>
            </td>
          </tr>
          <tr>
            <td>
              fqnd_regex
            </td>
            <td>
              <o-inputitems v-model="data.fqnd_regex" icon="tag" placeholder="Add an item" aria-close-label="Delete this item"> </o-inputitems>
            </td>
          </tr>
          <tr>
            <td>
              client_key
            </td>
            <td>
              <o-input placeholder="Client ID" v-model="data.client_key"> </o-input>
            </td>
          </tr>
          <tr>
            <td>
              available_licenses
            </td>
            <td>
              <o-slider :min="0" :max="alreadyUsedLicenses" ticks v-model="data.available_licenses"></o-slider>
            </td>
          </tr>
          <tr>
            <td>
              active
            </td>
            <td>
              <o-checkbox v-model="data.active"></o-checkbox>
            </td>
          </tr>
        </table>
      <section v-if="successNewClient" class="hero is-primary">
        <div class="hero-body">
          <p class="title">
            {{ successNewClient.password }}
          </p>
          <p class="subtitle">
            Please copy this Client secrect somewhere safe, it can never be restored again
          </p>
        </div>
      </section>
      </div>
      <footer class="card-footer">
        <o-button variant="danger" class="card-footer-item" @click="$emit('close')" >Close</o-button>
        <o-button variant="success" class="card-footer-item" @click="create()" >Modify</o-button>
      </footer>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          licensed_hosts: '',
          company: '',
          mode: '',
          username: '',
          local_account_id: '',
          history: []
        }
      }
    }
  },
  data () {
    return {
      data: null,
      alreadyUsedLicenses: 0
    }
  },
  mounted () {
    this.data = JSON.parse(JSON.stringify(this.item))
    this.data.available_licenses = parseInt(this.data.available_licenses)

    this.alreadyUsedLicenses = parseInt(this.data.available_licenses) + this.$store.getters.unusedLicenseAmount
  },
  methods: {
    create () {
      var body = {
        client_key: this.data.client_key,
        info: {
          company: this.data.company,
          fqnd_regex: this.data.fqnd_regex,
          available_licenses: this.data.available_licenses,
          active: this.data.active
        }
      }
      this.$store.dispatch('changeClient', body)
        .then(() => {
          this.$store.dispatch('getAllClients')
          this.$emit('close')
        })
        .catch(error => this.errorMessage(error))
    },
    errorMessage (message) {
      this.$oruga.notification.open({
        message: message,
        rootClass: 'toast-notification',
        variant: 'danger',
        position: 'bottom',
        duration: 5000
      })
    }
  }
}
</script>

<style scss scoped>
</style>
