<template>
   <div class="card">
    <div class="card-content">
      <div class="content">
        <table>
          <tr>
            <td>
              company
            </td>
            <td>
              {{ item.company }}
            </td>
          </tr>
          <tr>
            <td>
              licensed_hosts
            </td>
            <td>
              {{ item.licensed_hosts }}
            </td>
          </tr>
          <tr>
            <td>
              expiration_date
            </td>
            <td>
              {{ item.expiration_date }}
            </td>
          </tr>
          <tr>
            <td>
              active_clients
            </td>
            <td >
              <table>
                <tr v-for="clients in item.active_clients" :key="clients.host_name">
                <td>
                  {{ clients.host_name }}
                </td>
                <td>
                  {{ clients.last_connection }}
                </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              rejected_clients
            </td>
            <td >
              <table>
                <tr v-for="clients in item.failed_clients" :key="clients.host_name">
                <td>
                  {{ clients.host_name }}
                </td>
                <td>
                  {{ clients.last_connection }}
                </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>

      <div class="container">
  <div class="wrapper">

      <h3>Timeline</h3>

      <ul class="sessions">
        <li v-for="modify in item.history" :key="modify.timestamp">
          <div class="time">{{ modify.timestamp }}</div>
          <p>{{ modify.modifier.username }}: {{ modify.mode }}</p>
        </li>
      </ul>
      </div>
      </div>

      </div>
      <footer class="card-footer">
        <o-button variant="danger" class="card-footer-item" @click="$emit('close')" >Close</o-button>
      </footer>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          licensed_hosts: '',
          company: '',
          mode: '',
          username: '',
          local_account_id: '',
          history: []
        }
      }
    }
  }
}
</script>

<style scss scoped>

@mixin tablet-and-up {
    @media screen and (min-width: 769px) { @content; }
}
@mixin mobile-and-up {
    @media screen and (min-width: 601px) { @content; }
}
@mixin tablet-and-down  {
    @media screen and (max-width: 768px) { @content; }
}
@mixin mobile-only {
    @media screen and (max-width: 600px) { @content; }
}

ul, li{
  list-style: none;
  padding: 0;
}

.container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}
.wrapper{
  padding: 2rem;
  border-radius: 15px;
  overflow: auto;
  max-height: 50vh;
  min-width: 30vw;
}

.card {
  min-width: 50vw;
}

h1{
  font-size: 1.1rem;
  font-family: sans-serif;
}
.sessions{
  margin-top: 2rem;
  border-radius: 12px;
  position: relative;
}
li{
  padding-bottom: 1.5rem;
  border-left: 1px solid #abaaed;
  position: relative;
  padding-left: 20px;
  margin-left: 10px;
  &:last-child{
    border: 0px;
    padding-bottom: 0;
  }
  &:before{
    content: '';
    width: 15px;
    height: 15px;
    background: white;
    border: 1px solid #4e5ed3;
    box-shadow: 3px 3px 0px #bab5f8;
    box-shadow: 3px 3px 0px #bab5f8;
    border-radius: 50%;
    position: absolute;
    left: -10px;
    top: 0px;
  }
}
.time{
  color: #2a2839;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  @include mobile-and-up{
    font-size: .9rem;
  }
  @include mobile-only{
    margin-bottom: .3rem;
    font-size: 0.85rem;
  }

}
p{
  color: #4f4f4f;
      font-family: sans-serif;
  line-height: 1.5;
  margin-top:0.4rem;
  @include mobile-only{
    font-size: .9rem;
  }
}
</style>
