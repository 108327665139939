<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item" href="https://aveniq.ch">
        <img src="https://bulma.io/images/bulma-logo.png" width="112" height="28">
      </a>

      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-start">
        <router-link :to="{ name: 'Clients' }" class="navbar-item">Clients</router-link>
        <router-link :to="{ name: 'Labels' }" class="navbar-item">Labels</router-link>
        <router-link v-if="$store.state.license.isMaster" :to="{ name: 'Tenants' }" class="navbar-item">Tenants</router-link>
        <router-link :to="{ name: 'Configuration' }" class="navbar-item">Config</router-link>
      </div>

      <div id="navbarBasicExample" class="navbar-menu">
        <div class="navbar-item">
          <div class="tag is-success" v-if="userName">Welcome, {{userName}}</div>
            <i class="el-icon-user"></i>
          </div>
      </div>
      <div class="navbar-item">
        <div v-if="$store.state.license.isMaster" class="tag is-danger">master mode</div>
        <div v-else class="tag is-danger">slave mode</div>
      </div>
      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <a v-if="isAuthenticated" class="button is-danger" @click="logout()">
              Log out
            </a>
            <a v-if="!isAuthenticated" class="button is-success" @click="login()">
              Log in
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { useMsal } from '../composition-api/useMsal'
import { loginRequest } from '../authConfig'
import { useIsAuthenticated } from '../composition-api/useIsAuthenticated'
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser'
import { reactive, onMounted, watch } from 'vue'

export default {
  setup () {
    const { instance, accounts, inProgress } = useMsal()
    const isAuthenticated = useIsAuthenticated()

    const state = reactive({
      resolved: false,
      data: {}
    })
    return { instance, accounts, inProgress, isAuthenticated, state }
  },
  methods: {
    login () {
      this.instance.loginRedirect(loginRequest)
        .then(this.instance.acquireTokenSilent({
          ...loginRequest
        }))
        .then(response => {
          if (this.inProgress.value === InteractionStatus.None) {
            const authToken = 'Bearer ' + response.accessToken
            const graphData = fetch.get('hello', {
              Headers: {
                Authorization: authToken
              }
            })
            this.state.data = graphData
            this.state.resolved = true
          }
        })
    },
    logout () {
      this.instance.logoutRedirect()
    }
  },
  computed: {
    userName () {
      if (this.accounts.length > 0) {
        const name = this.accounts[0].name
        if (name) {
          const splittedName = name.split(' ')
          return splittedName[0] + ' ' + splittedName[1]
        }
      }
      return ''
    }

  }
}
</script>

<style>

</style>
