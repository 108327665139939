<template>
   <div class="card">
    <div class="card-content">
      <div class="content">
        <table>
          <tr>
            <td>
              company
            </td>
            <td>
              <o-input placeholder="Aveniq" v-model="company"> </o-input>
            </td>
          </tr>
          <tr>
            <td>
              fqnd_regex
            </td>
            <td>
              <o-inputitems v-model="fqnd_regex" icon="tag" placeholder="Add an item" aria-close-label="Delete this item"> </o-inputitems>
            </td>
          </tr>
          <tr>
            <td>
              client_key
            </td>
            <td>
              <o-input placeholder="Client ID" v-model="client_key" :disabled="true"> </o-input>
            </td>
          </tr>
           <tr>
            <td>
              available_licenses
            </td>
            <td>
              <o-slider :min="0" :max="$store.getters.unusedLicenseAmount" ticks v-model="available_licenses"></o-slider>
            </td>
          </tr>
          <tr>
            <td>
              active
            </td>
            <td>
              <o-checkbox v-model="active"></o-checkbox>
            </td>
          </tr>
        </table>
      <section v-if="successNewClient" class="hero is-primary">
        <div class="hero-body">
          <p class="title">
            {{ successNewClient.password }}
          </p>
          <p class="subtitle">
            Please copy this Client secrect somewhere safe, it can never be restored again
          </p>
        </div>
      </section>
      </div>
      <footer class="card-footer">
        <o-button variant="danger" class="card-footer-item" @click="$emit('close')" >Close</o-button>
        <o-button v-if="!successNewClient" variant="success" class="card-footer-item" @click="create()" >Create</o-button>
      </footer>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      fqnd_regex: [],
      company: '',
      client_key: '',
      successNewClient: null,
      active: true,
      available_licenses: 0
    }
  },
  methods: {
    create () {
      var body = {
        client_key: this.client_key,
        info: {
          company: this.company,
          fqnd_regex: this.fqnd_regex,
          active: this.active,
          available_licenses: this.available_licenses
        }
      }
      this.$store.dispatch('createClient', body)
        .then(data => {
          this.successNewClient = data
        })
        .catch(error => this.errorMessage(error))
    },
    errorMessage (message) {
      this.$oruga.notification.open({
        message: message,
        rootClass: 'toast-notification',
        variant: 'danger',
        position: 'bottom',
        duration: 5000
      })
    }
  },
  watch: {
    company () {
      var output = ''
      for (var i = 0; i < this.company.length; i++) {
        if (this.company.charCodeAt(i) <= 57 && this.company.charCodeAt(i) >= 48) {
          output += this.company.charAt(i)
        } else if (this.company.charCodeAt(i) <= 90 && this.company.charCodeAt(i) >= 65) {
          output += this.company.charAt(i).toLowerCase()
        } else if (this.company.charCodeAt(i) <= 122 && this.company.charCodeAt(i) >= 97) {
          output += this.company.charAt(i)
        } else if (this.company.charAt(i) === '$') {
          output += ''
        } else if (this.company.charAt(i) === '_') {
          output += '_'
        } else if (this.company.charAt(i) === 'ü') {
          output += 'ue'
        } else if (this.company.charAt(i) === 'ö') {
          output += 'oe'
        } else if (this.company.charAt(i) === 'ä') {
          output += 'ae'
        } else if (this.company.charAt(i) === ' ') {
          output += '_'
        } else if (this.company.charAt(i) === '-') {
          output += '_'
        }
      }
      this.client_key = output
    }
  }
}
</script>

<style scss scoped>
</style>
