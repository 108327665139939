<template>
  <div>
    <oruga-navbar />
    <div class="container">
      <router-view/>
    </div>
  </div>
</template>

<script lang="js">
import OrugaNavbar from './components/OrugaNavbar'
export default {
  components: {
    OrugaNavbar
  }
}
</script>
<style>
</style>
